/* eslint-disable import/no-duplicates */
import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// @types
import { ActionMap, AuthState, AuthUser, FirebaseContextType } from "../@types/auth.type";
//
import { FIREBASE_API } from "../config";
// import UserDataService from '../services/user.service';
// import { truncate } from 'fs/promises'; 

// ----------------------------------------------------------------------

// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  console.log("Localhost!")
  firebase.firestore().useEmulator('localhost', 8080);
  firebase.auth().useEmulator('http://localhost:9099/');
}

if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_API);
  firebase.firestore();
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

enum Types {
  Initial = 'INITIALISE'
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [profile, setProfile] = useState<firebase.firestore.DocumentData | undefined>();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const docRef = firebase.firestore().collection('users').doc(user.uid);
          docRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                setProfile(doc.data());
              }
              dispatch({
                type: Types.Initial,
                payload: { isAuthenticated: true, user }
              });
            })
            .catch((error) => {
              console.log(error);
              dispatch({
                type: Types.Initial,
                payload: { isAuthenticated: true, user }
              });
            });
        } else {
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),
    [dispatch]
  );

  const login = (email: string, password: string) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  // const register = async (email: string, password: string) => {
  //   const result = await firebase.auth().createUserWithEmailAndPassword(email, password);
  //   try {
  //     const userData = await InviteDataService.getByEmail(email);
  //     await UserDataService.create(userData, result.user?.uid);
  //   } catch {
  //     console.log('User has not been invited');
  //   }
  //   await AuthIDsDataService.create(email, result.user?.uid);
  // }

  const logout = async () => {
    await firebase.auth().signOut();
  };

  const resetPassword = async (email: string) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const changePassword = async (newPassword: string, userID: string) => {
    await firebase.auth().currentUser?.updatePassword(newPassword);
    // await UserDataService.update(userID, { changePasswordOnNextLogin: false });
  }

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: profile?.id,
          email: profile?.email,
          photoURL: profile?.photoURL,
          firstName: profile?.firstName,
          lastName: profile?.lastName,
          displayName: profile?.displayName,
        },
        login,
        // register, 
        loginWithGoogle,
        loginWithFaceBook,
        loginWithTwitter,
        logout,
        resetPassword,
        changePassword,
        updateProfile: () => {}
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
