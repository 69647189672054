// routes
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AnimatedRoutes from './routes';

// ----------------------------------------------------------------------

import palette from './theme/palette';
import typography from './theme/typography';
import breakpoints from './theme/breakpoints';
import componentsOverride from './theme/overrides';
import shadows, { customShadows } from './theme/shadows';
import { CssBaseline, ThemeOptions } from '@mui/material';
import { useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';

export default function App() {

  const isLight = false; //themeMode === 'light';

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 4 },
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight]
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AnimatePresence>
        <AnimatedRoutes />
      </AnimatePresence>
    </ThemeProvider>
  );
}
