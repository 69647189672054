import { Suspense, lazy, ElementType } from 'react';
import { Outlet, useRoutes } from 'react-router-dom';
import { CollectionProvider } from '../contexts/CollectionContext';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  );
};

export default function AnimatedRoutes() {
  return useRoutes([
    // {
    //   path: 'auth',
    //   children: [
    //     {
    //       path: 'login',
    //       element: (
    //         <GuestGuard>
    //           <Login />
    //         </GuestGuard>
    //       ),
    //     }
    //   ],
    // },

    // Dashboard Routes
    { path: '/', element: <Home /> },
    { 
      path: '/:collectionID',
      element: (
        <CollectionProvider>
          <Outlet/>
        </CollectionProvider>
      ),
      children: [
        { element: <Browser />, index: true },
        { path: 'album/:albumID', element: <Album /> },
      ]
    },
    // { path: '*', element: <NotFound/> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
// const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// Dashboard
const Home = Loadable(lazy(() => import('../pages/Home')));
const Browser = Loadable(lazy(() => import('../pages/Browser')));
const Album = Loadable(lazy(() => import('../pages/Album')));
// const NotFound = Loadable(lazy(() => import('../pages/Page404')));