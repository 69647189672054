import { ReactNode, createContext, useEffect, useState } from 'react';
import { Collection } from '../@types/collection.type';
import { useParams } from 'react-router-dom';
import { getCollectionByID } from '../services/collection.service';

// ----------------------------------------------------------------------

type CollectionContextProps = {
  collection?: Collection;
}

const initialState: CollectionContextProps = {  };

const CollectionContext = createContext(initialState);

const mockAlbumData = [
  { 
    id: "F12-0",
    name: "Rumours",
    number: 0,
    artist: {
      id: "F12",
      name: "Fleetwood Mac",
    },
    year: 1977,
    genres: ["Rock", "Blues"],
    styles: ["Pop Rock"],
    image: "https://i.discogs.com/WpuYpr4IADSy49WTIw4r6YW5p_AWCNQVZg6XvaQ0Yw8/rs:fit/g:sm/q:90/h:583/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTM3NDg4/MS0xMTkzMDMzNzU0/LmpwZWc.jpeg",
    tracklist: [
      { name: "Second Hand News", duration: 163 },
      { name: "Dreams", duration: 254 },
      { name: "Never Going Back Again", duration: 122 },
      { name: "Don't Stop", duration: 191 },
      { name: "Go Your Own Way", duration: 218 },
      { name: "Songbird", duration: 200 },
      { name: "The Chain", duration: 268 },
      { name: "You Make Loving Fun", duration: 211 },
      { name: "I Don't Want To Know", duration: 191 },
      { name: "Oh Daddy", duration: 234 },
      { name: "Gold Dust Woman", duration: 291 },
    ]
  }, 
  { 
    id: "Z3-0",
    name: "Eliminator",
    number: 0,
    artist: {
      id: "Z3",
      name: "ZZ Top",
    },
    year: 1983,
    genres: ["Rock"],
    styles: ["Pop Rock", "Hard Rock", "Blues Rock"],
    image: "https://i.discogs.com/G97lciR_13OtiULAG_3mzUzauQ5HRQIb6m_M6biZ4r0/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTQ4MjUw/ODUtMTM3NjY3NTA3/NS01MzI1LmpwZWc.jpeg",
    tracklist: [
      { name: "Gimme All Your Lovin", duration: 239 },
      { name: "Got Me Under Pressure", duration: 239 },
      { name: "Sharp Dressed Man", duration: 253 },
      { name: "I Need You Tonight", duration: 374 },
    ]
  },
  { 
    id: "L2-0",
    name: "Untitled",
    number: 0,
    artist: {
      id: "L2",
      name: "Led Zeppelin",
    },
    year: 1971,
    genres: ["Rock"],
    styles: ["Hard Rock", "Blues Rock"],
    image: "https://i.discogs.com/FXDeOFtCi0aT4qcwxrJijofnrpAMiwzuGckrCz7wRNQ/rs:fit/g:sm/q:90/h:572/w:575/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTUxMzU4/ODQtMTM4OTcxMjQ3/Ny00ODA3LmpwZWc.jpeg",
    tracklist: [
      { name: "Black Dog" },
      { name: "Rock And Roll" },
      { name: "The Battle Of Evermore" },
      { name: "Stairway To Heaven" },
    ]
  }
]

const mockCollectionData: Collection = {
  id: "123456",
  albums: mockAlbumData,
  favorites: ["F12-0", "Z3-0", "L2-0"],
  genres: ["Pop", "Rock", "Jazz", "Blues"],
  decades: [1980, 1990, 2000, 2010],
  user: {
    id: "333",
    displayName: "Cameron Miller",
  }
}

// ----------------------------------------------------------------------

type CollectionProviderProps = {
  children: ReactNode;
};

function CollectionProvider({ children }: CollectionProviderProps) {

  const { collectionID } = useParams();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [collection, setCollection] = useState<Collection>();
  
  const fetchCollection = async (collectionID?: string) => {
    if (!collection) {
      console.log(`Setting collection with ID: ${collectionID}`);
      const data = await getCollectionByID(collectionID);
      if (data) setCollection(data);
      console.log(data);
    }
  };

  useEffect(() => {
    fetchCollection(collectionID);
  }, [collectionID]);

  return (
    <CollectionContext.Provider
      value={{
        collection,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
}

export { CollectionProvider, CollectionContext };
