import { Collection } from "../@types/collection.type";
import firebase from "../firebase";
import { trying } from "../utils/promises";
const db = firebase.collection("/collections");

export async function getCollectionByID(collectionID?: string) {
  if (!collectionID) return undefined;

  const [error, result] = await trying(db.doc(collectionID).get());
  if (error) console.log(error)
  return result?.data() ? { id: result?.id, ...result.data() } as Collection : undefined;
}