export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// export const FIREBASE_API = {
//   apiKey: "AIzaSyDeGAyRIFpwx76ZZ9qMpt2wCdHuyMMvvD4",
//   authDomain: "vinyl-browser.firebaseapp.com",
//   projectId: "vinyl-browser",
//   storageBucket: "vinyl-browser.appspot.com",
//   messagingSenderId: "67340878977",
//   appId: "1:67340878977:web:16a5c3964e80e4b092b980"
// };